import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@kajabi/sage-react';

function EmailInput({ disabled, defaultValue, label, emailField, placeholder }) {
  const [email, setEmail] = React.useState(defaultValue);
  const {
    setValue,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (defaultValue) {
      setValue(emailField, defaultValue, { shouldValidate: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run once only!

  const handleChange = (event) => {
    setEmail(event.target.value);
    setValue(emailField, event.target.value, { shouldValidate: true });
  };

  const emailError =
    errors && emailField.split('.').reduce((res, prop) => res && res[prop], errors);

  return (
    <Input
      inputType="email"
      label={label}
      name={label}
      id={emailField}
      value={email}
      onChange={handleChange}
      placeholder={t(placeholder)}
      disabled={disabled}
      hasError={emailError}
      message={emailError ? t('form.invalid_email') : null}
    />
  );
}

EmailInput.propTypes = {
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  emailField: PropTypes.string,
  placeholder: PropTypes.string,
};

EmailInput.defaultProps = {
  disabled: false,
  defaultValue: '',
  label: null,
  emailField: 'email',
  placeholder: 'form.email',
};

export default EmailInput;
