/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import SageToast from 'apps/commerce/common/checkout/components/SageToast';
import ToastContext from './ToastContext';

function ToastProvider({ children }) {
  const [config, setConfig] = useState({
    title: '',
    type: null,
    description: '',
    timeout: 3500,
    icon: 'danger-filled',
    isActive: false,
  });

  const showToast = useCallback((props) => {
    setConfig((prev) => ({ ...prev, isActive: true, ...props }));
  }, []);

  const hideToast = useCallback(() => {
    setConfig({ ...config, isActive: false });
  }, [config]);

  const contextValue = React.useMemo(
    () => ({
      showToast,
      hideToast,
      ...config,
    }),
    [config, hideToast, showToast],
  );

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      <SageToast />
    </ToastContext.Provider>
  );
}

export default ToastProvider;
